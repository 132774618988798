
// body{
//     font-family: 'futurabook';
// }
// b{
//     font-family: 'futurabold';
// }

.swiper {
    width: 100%;
    height: auto;
  }