@import "common/variables";

@import "~bootstrap/scss/bootstrap";


@import "common/fonts";
@import "common/mixins";
@import "common/animate";
@import "common/elementos";

@import "layouts/header";
@import "layouts/footer";
@import "layouts/home";