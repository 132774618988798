html,body{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4em;
    background-color: #FCFCFC;
    color:#344054;

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }

}

h1,h2,h3,h4,h5,h6{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
}

h2{
    font-size: 48px;
    line-height: 52px;
}

h3{
    font-size: 30px;
}

a{
    color:$colorPrincipal;
    text-decoration: none;
}

ul{
    background-color: $gris;
    li{
        list-style: none;
        margin-bottom: 15px;
        font-weight: 600;

        &:last-child{
            margin-bottom: 0px;
        }
    }
}

.btn-primary{
    @include media-breakpoint-down(md) {
        width:100%;
    } 
}

.color-principal{
    color:$colorPrincipal;
}

.color-secundario{
    color: $colorSecundario;
}

.btn-primary{
    background-color: $colorPrincipal;
    border-color: $colorPrincipal;
    font-weight: 700;
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    &:hover{
        background-color: $colorSecundario;
        border-color: $colorSecundario;
    }
}

header{
    border-bottom: solid 1px transparentize(black,.9);
}

section{
    padding-bottom: 115px;

    @include media-breakpoint-down(md) {
        padding-bottom: 75px;
    }

}


.modulo1{

    p{
        a{
            color:$colorPrincipal;
        }
    }

}


.modulo2{
    .cifra{
        font-weight: 700;
        font-size: 3rem;
        color: $colorPrincipal;
        line-height: 1.3em;

        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }        

    }
}

footer{
    font-size: 14px;
    a{
        color:black;
        text-decoration: none;
        margin-right: 10px;
        display: inline-block;
    }
}

#modal-cookies {
    .cont-btn {
        display: inline-flex;
        position: absolute;
        right: 0px;
        top: 0px;
        @include media-breakpoint-down(lg) {
            display: block;
            position: relative;
            margin-top: 15px;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        .btn-mas-info {
            width: 174px !important;
            height: 59px;
            color: white;
            //background-image: url("../images/bg-btn-marron.png");
            background-size: cover;
            background-repeat: no-repeat;
            
            font-size: 1rem;
            opacity: 1;
            background-color: transparent;
            text-transform: uppercase;
            border: none;
            padding-top: 15px;
            margin-right: 15px;
            &:hover {
                opacity: 0.6;
            }
            @include media-breakpoint-down(sm) {
                position: relative;
                width: 150px !important;
                font-size: 0.8rem;
            }
        }
        .btn-acepto {
            width: 103px !important;
            height: 59px;
            color: white;
            //background-image: url("../images/bg-btn-verde.png");
            background-size: cover;
            background-repeat: no-repeat;
            
            font-size: 1rem;
            opacity: 1;
            background-color: transparent;
            text-transform: uppercase;
            border: none;
            &:hover {
                opacity: 0.6;
            }
            @include media-breakpoint-down(sm) {
                position: relative;
                width: 75px !important;
                font-size: 0.8rem;
            }
        }
    }
}

#modal-cookies {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999;
    background-color: $colorPrincipal;
    p {
        
        font-size: 1rem;
        line-height: 1.2em;
        color: white;
        margin-bottom:0px;
        @include media-breakpoint-down(sm) {
            text-align: center;
            width: 100%;
            padding-right: 15px;
            float: none;
        }
        a {
            
            font-size: 1rem;
            line-height: 1.2em;    
            color:  white;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .chck label {
        color: white;
        margin-right: 10px;
    }
    .cont-cookies{
        width: 100%;
        max-width: 100%;
    }
    .contenedor-botones {
        margin-top: 15px;
        @include media-breakpoint-down(lg) {
            display:block!important;
        }
        .btn-mas-info {
            height: 40px;
            
            font-size: 1rem;
            line-height: 1.2em;
            color: black;
            background-color: #daa0a3;
            cursor: pointer;
            display: block;
            border-radius: 1.75em;
            border: none;
            width: unset;
            &:hover {
                opacity: 0.6;
            }
            @include media-breakpoint-down(sm) {
                position: relative;
                // width: 150px !important;
                font-size: 0.8rem;
                padding: 12px 25px;
            }
        }
        .btn-acepto,
        #btn-configurar {
            background-color:white;
            margin-left:15px;
            
            font-weight: 400;
            text-align: center;
            color: $colorPrincipal;
            height: auto;
            padding: 3px;
            border: 3px solid white;
            border-radius: inherit;
            width:30%;
            &:hover,
            &:focus,
            &:active,
            &.active{
                background-color: $colorPrincipal;
                color:white;
                border: 3px solid white;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
    #formCookies{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        .btn{
            background-color:white;
            border:solid 1px white;
            color:$colorPrincipal;
            border-radius: 0;

            &:hover,
            &:focus,
            &:active,
            &.active{
                background-color: $colorPrincipal;
                color:white;
                border: 1px solid white;
            }

        }

    }
}