/**
 * transiciones de elementos
 * @param  {[all 1s ease]} $value 
 */
@mixin transition($value) {
    -webkit-transition:$value; /* Safari */
    -moz-transition: $value;
    -o-transition:$value;
    transition:$value;
}

/**
 * bordes curvos
 * @param  {[supizq supder infder infizq]} $value 
 */
@mixin border-radius($value) {
  -webkit-border-radius: $value;
     -moz-border-radius: $value;
      -ms-border-radius: $value;
          border-radius: $value;
}

/**
 * sombra de cajas
 * @param  {[h-shadow v-shadow blur spread color]} $value 
 */
@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

/**
 * sombra de texto
 * @param  {[h-shadow v-shadow blur spread color]} $value 
 */
@mixin text-shadow($value) {
  -webkit-text-shadow: $value;
  -moz-text-shadow: $value;
  text-shadow: $value;
}

/**
 * transformacion css
 * @param  {usar valores de la transformacion necesaria} $value 
 */
@mixin transform($value){
    -ms-transform: $value; /* IE 9 */
    -webkit-transform: $value; /* Chrome, Safari, Opera */
    transform: $value;

}

/**
 * [giroinfinito :: hace giro infinito al objeto]
 * @param  {[type]} $duracion [velocidad de una vuelta en segundos]
 */
@mixin giroinfinito($duracion){
    -webkit-animation-name: spin;
    -webkit-animation-duration: $duracion;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: $duracion;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: $duracion;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-transition: rotate(3600deg);

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}